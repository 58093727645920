import '@fancyapps/fancybox'
$.fancybox.defaults.closeExisting = true
$.fancybox.defaults.hash = false

$('[data-modal-html]').on('click', function () {
  const id = $(this).data('modal-html')
  $.fancybox.open({
    src: id,
    type: 'inline',
    opts: {
      afterShow : function( instance, current ) {
        // console.info( 'done!' );
      }
    }
  });
})

function checkEmpty(el) {
  if (el.val()) {
    el.addClass('_no-empty')
  } else {
    el.removeClass('_no-empty')
  }
}

$('.modal__input').on('input', function () {
  checkEmpty($(this))
})
$('.modal__select').on('input', function () {
  checkEmpty($(this))
})

process.nextTick(() => {
  $('.modal__input').each(function () {
    checkEmpty($(this))
  })
  $('.modal__select').each(function () {
    checkEmpty($(this))
  })
});
