$(function () {
  $('.scroll-up').on('click', function () {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  $('.js-ancor-to-app').on('click', function () {
    const offset = $('.mobile-app').offset().top
    window.scrollTo({
      top: offset - 120,
      behavior: "smooth"
    });
  })
})