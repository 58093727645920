import IMask from 'imask';
import lsCache from 'lscache'

$('.js-phone').each(function () {
  const phoneMask = IMask(this, {
    mask: '+{7} (000) 000-00-00'
  });
})

const forms = document.querySelectorAll('form')
const requiredFields = document.querySelectorAll('[required]')

const loader = document.createElement('div');
loader.innerHTML = '<div></div>';
loader.classList.add('btn__loader')

const sending = {
  handling(form) {
    this.valid = true
    form.querySelectorAll('[required]').forEach(field => {
      this.validation(field)
    });

    if (this.valid) {
      var btn = form.querySelector('.js-send-form')
      // btn.prepend(loader)
      btn.setAttribute('disabled', true)
      setTimeout(() => {
        this.send(form)
      }, 20);
    }
  },
  validation(field) {
    if (field.validity.valueMissing) {
      field.classList.add('_error')
      this.valid = false
    }
  },
  send(form) {
    var formData = ''
    form.querySelectorAll('[name]').forEach(element => {
      formData += `${element.getAttribute('name')}=${element.value}&`
    });
    formData = formData.slice(0, formData.length - 1)
    const method = form.getAttribute('method')
    const action = form.getAttribute('action')
    const contentType = form.getAttribute('data-content-type')

    var xhr = new XMLHttpRequest();
    var btn = form.querySelector('.js-send-form')
    xhr.open(method, action, false)
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.send(formData)

    if (xhr.status === 200) {
      const response = JSON.parse(xhr.responseText)
      if (response.token) {
        console.log(5);
        // const mo = document.querySelector('#modal-success')
        // btn.querySelector('.btn__loader').remove()
        // btn.removeAttribute('disabled')
        // form.reset()
        lsCache.set('token', response.token, response.ttl)
        lsCache.set('profile', response.user, response.ttl)
        lsCache.set('blockTokenRefresh', true, response.ttl / 2)
        window.location.replace(`${window.location.href}crm/`)
      }
    } else {
      console.error(xhr.responseText);
    }
  }
}

function formSubmit(form) {
  form.addEventListener('submit', (event) => {
    event.preventDefault()
    sending.handling(form)
  })

  const button = form.querySelector('.js-send-form')
  if (button !== null) {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      const domEvent = document.createEvent('Event')
      domEvent.initEvent('submit', false, true)
      event.target.closest('form').dispatchEvent(domEvent)
    })
  }
}

forms.forEach((form) => {
  formSubmit(form)
})
requiredFields.forEach((field) => {
  field.addEventListener('input', (event) => {
    field.classList.remove('_error')
  })
})